import React from 'react';
import PropTypes from 'prop-types';
import SpotifyPlayer from 'components/spotify-player';
import Styles from './takeover.module.css';

const Takeover = ({ image, heading, spotifyId, description }) => {
  return (
    <article className={Styles.takeover}>
      <div className={Styles.line} />
      <picture className={Styles.image}>
        <img src={image} aria-hidden="true" loading="lazy" alt="" />
      </picture>

      <div className={Styles.content}>
        <h2 className={Styles.heading}>{heading}</h2>
        {description && <p className={Styles.description}>{description}</p>}
        <div className={Styles.playlist}>
          <SpotifyPlayer title={heading} uri={spotifyId} />
        </div>
      </div>
    </article>
  );
};

Takeover.defaultProps = {};

Takeover.propTypes = {
  heading: PropTypes.string.isRequired,
  spotifyId: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Takeover;

import 'css/takeovers.css';

import { graphql, useStaticQuery } from 'gatsby';

import { HEADLINE_TYPES } from 'components/headline';
import AppContext from '../common/context.app';
import ThemeContext, { THEMES } from '../common/context.theme';
import PageHero from '../components/page-hero';
import React, { useContext, useEffect } from 'react';
import Takeover from 'components/takeover';
import SEO from '../components/seo';
import TakeoversShape from '../images/shapes/takeovers.svg';

const TakeoversPage = () => {
  const { setCurrentPage } = useContext(AppContext);
  const { setTheme } = useContext(ThemeContext);
  useEffect(() => {
    setTheme(THEMES.dark);
    setCurrentPage('/playlist-takeovers/');
  }, [setTheme, setCurrentPage]);

  const data = useStaticQuery(graphql`
      query {
          takeoversJson {
              takeovers {
                  cover
                  spotify_id
                  description
                  title
              }
          }
      }
  `);

  const { takeovers } = data.takeoversJson;

  return (
    <div className="page">
      <SEO title="Playlist Takeovers"/>
      <PageHero
        className="takeovers-hero"
        headline={HEADLINE_TYPES.TAKEOVERS}
        shape={TakeoversShape}
      >
        Some of Spotify's biggest Caribbean influenced playlists, specially curated for Carnival by your favourite
        people
      </PageHero>

      <section className="nhc-container-left nhc-container-left--mobile-only nhc-container-right">
        {takeovers.map(({ id, cover, date, title, spotify_id, description }, i) => {
          return (
            <Takeover
              key={i}
              heading={title}
              image={cover}
              spotifyId={spotify_id}
              description={description}
            />
          );
        })}
      </section>
    </div>
  );
};

export default TakeoversPage;

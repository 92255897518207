import PropTypes from 'prop-types';
import React from 'react';
import Styles from './spotify-player.module.css';
import PlaceHolderSVGWhite from './placeholder-white.svg';
import PlaceHolderSVGBlack from './placeholder-black.svg';


const SpotifyPlayer = props => {
  const EMBED_SRC = 'https://open.spotify.com/';
  const EMBED_URI = props.uri.replace(EMBED_SRC, `${EMBED_SRC}embed/`);

  const podcast = EMBED_URI.indexOf('episode') !== -1;
  const WIDTH = '100%';
  const HEIGHT = podcast ? '180' : '374';

  return (
    <div
      className={`${props.className} ${Styles.spotifyPlayer} ${
        podcast ? Styles.podcast : ''
      }`}
      style={{ backgroundImage: `url(${podcast ? PlaceHolderSVGBlack : PlaceHolderSVGWhite})` }}
    >
      <iframe
        title={props.title}
        src={EMBED_URI}
        width={WIDTH}
        height={HEIGHT}
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
      />
    </div>
  );
};

SpotifyPlayer.defaultProps = {};

SpotifyPlayer.propTypes = {
  uri: PropTypes.string.isRequired,
};

export default SpotifyPlayer;
